import React, { forwardRef } from 'react'

interface IHighLightProps {
  className?: string
  isReversed?: boolean
  children: string | JSX.Element | JSX.Element[]
}

const HighLight = forwardRef<HTMLHeadingElement, IHighLightProps>(
  ({ className, isReversed, children }, ref) => {
    const direction = isReversed ? 'flex-row-reverse' : ''

    return (
      <div
        ref={ref}
        className={`flex ${direction} ${className || ''} mt-10 md:mt-16 w-full`}
      >
        <div className="flex-shrink-0 w-1 bg-red rounded-full" />
        <div className={`${isReversed ? 'ml-4 mr-8 ' : 'ml-8 mr-4 max-w-md'}`}>
          <p className="text-lg md:text-2xl leading-tight md:leading-normal ">
            {children}
          </p>
        </div>
      </div>
    )
  }
)

HighLight.defaultProps = {
  className: '',
  isReversed: false,
}

export default HighLight
